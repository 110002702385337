export const multiDayPricingList = (multi_day_pricing) => {
  if (!multi_day_pricing || Object.keys(multi_day_pricing || {}).length <= 0) {
    return null
  }

  if (multi_day_pricing != null) {
    return multi_day_pricing?.filter(discount => discount?.discount_days > 2).map(d => d.discount_days)
  } else {
    return null
  }
}