import {
  USER_SMALL_PARAMS,
  motoPhotoID,
  userPhotoID,
  SEARCH_IMAGE_PARAMS,
  USER_LARGE_PARAMS,
} from 'constants/images/sizesAndQuality'
import PENDING_STATES from 'components/dashboard/utils/pendingStates'
import { CLOUDINARY_NAME } from 'constants/images/cloudinary'
import cloudinary from 'cloudinary-core'
import { buildBikeURL, parseDateRanges } from '../../utils'
import moment from 'moment'
import { makeClosedDays } from './bikeProfileAPIConnector'
import { find_price_N_cents } from 'components/search/calls/find_price_N_cents'
import { multiDayPricingList as findMultiDayPricingList } from 'components/utils/multiDayPricingList'

const cl = new cloudinary.Cloudinary({
  cloud_name: CLOUDINARY_NAME,
})

const ownerAvatarURL = (avatarID) =>
  cl.url(userPhotoID(avatarID), USER_SMALL_PARAMS)

const ownerAvatarURLLarge = (avatarID) =>
  cl.url(userPhotoID(avatarID), USER_LARGE_PARAMS)

const motorcycleImageURL = (motoID) =>
  cl.url(motoPhotoID(motoID), SEARCH_IMAGE_PARAMS)

const motoInfo = (data) => {
  //setting up variables for multiday hack
  let dailyRentalPrice = 0
  let multiday5 = 0
  let multiday10 = 0
  let multiday15 = 0
  let multidayN = 0
  const multiDayPricingList = data.motorcycle.multi_day_pricing
  dailyRentalPrice = data.motorcycle.daily_rental_price.daily_rental_price_cents

  if (multiDayPricingList && multiDayPricingList.length > 0) {
    multidayN = find_price_N_cents(dailyRentalPrice, multiDayPricingList)
  }

  multiday5 = dailyRentalPrice - dailyRentalPrice * (5 / 100)
  multiday10 = dailyRentalPrice - dailyRentalPrice * (10 / 100)
  multiday15 = dailyRentalPrice - dailyRentalPrice * (15 / 100)

  return {
    bike_id: data.motorcycle.pretty_id,
    bike_make: data.motorcycle.make,
    bike_model: data.motorcycle.model,
    bike_year: data.motorcycle.year,
    bike_trips_taken: data.motorcycle.trips_taken,
    bike_has_multiday_discount: data.motorcycle.multi_day_discounts,
    bike_multi_day_pricing: findMultiDayPricingList(Object.keys(multiDayPricingList).length > 0 ? multiDayPricingList : null),
    bike_can_book_instantly: data.motorcycle.can_book_instantly,
    bike_reviews_average: data.motorcycle.reviews_average,

    bike_day_price_cents: dailyRentalPrice,
    bike_day_price_currency: data.motorcycle.daily_rental_price.daily_rental_price_currency,

    bike_multiday_discount: {
      day_price_N_cents: multidayN,
      day_price_5_cents: multiday5,
      day_price_10_cents: multiday10,
      day_price_15_cents: multiday15,
    },

    bike_image: motorcycleImageURL(
      data.motorcycle.images[0].cloudinary_public_id
    ),
    bike_image_cloudinary_public_id: motoPhotoID(
      data.motorcycle.images[0].cloudinary_public_id
    ),
    bike_delivery_fees_information:
      data.financial.pricing.request_details.delivery_fees_information,
    bike_address_1: data.booking.location.address_street_1,
    bike_address_2: data.booking.location.address_street_2,
    bike_address_city: data.booking.location.city,
    bike_address_state_abbr: data.booking.location.region_abbreviation,
    bike_address_postal_code: data.booking.location.postal_code,
    bike_address_country_abbr: data.booking.location.country_abbreviation,
    bike_address_country: data.booking.location.country,
    bike_timezone: data.owner.is_partner
      ? data.owner.timezone
      : data.reservation.timezone_of_bike_at_time_booking
      ? data.reservation.timezone_of_bike_at_time_booking
      : 'America/Chicago',
    motorcycle_active: data.motorcycle.state === 'active' ? true : false,
    //TODO: This should really be MOTORCYCLE.LOCATION
    motorcycle_url: buildBikeURL({
      country: data.booking.location.country_abbreviation,
      region: data.booking.location.region_abbreviation,
      city: data.booking.location.city,
      year: data.motorcycle.year.toString(),
      make: data.motorcycle.make,
      model: data.motorcycle.model,
      bikeID: data.motorcycle.pretty_id,
    }),
    bike_blackout_dates: parseDateRanges(data.motorcycle.blackout_dates),
    bike_closed_days:
    data.owner.is_partner && data.owner.partner_info
    ? makeClosedDays(data.owner.partner_info.partner_working_hours)
    : [],
    bike_is_same_day_rentable: data.motorcycle.same_day_rentable,
    bike_same_day_rental_notice_hours: data.motorcycle.same_day_rental_notice_hours,
    working_hours: data.motorcycle.working_hours,
    booked_through_dealer_testride: data.financial.pricing.request_details.booked_through_dealer_testride
  }
}

const testState = (data) => {
  let state = data.reservation.state
  let actions = data.reservation.reservation_actions

  if (PENDING_STATES.includes(state)) {
    //hack: then we check that end date. if it's after today + it's pending, we 'expire it'
    let startDate = data.reservation.start_at
    if (moment().isAfter(moment(startDate))) {
      return { state: 'expired', actions: [] }
    }
  }

  //hack: we do a second check, this time to sniff if we have a leave_feedback action open
  if (actions.includes('leave_feedback')) {
    return { state: 'leave_feedback', actions: actions }
  }

  return { state, actions }
}

const reservationInfo = (data) => {
  return {
    rider_timezone: data.rider.timezone,
    rider_country: data.rider.location.country_abbreviation,
    location: data.booking.location,
    latitude: data.booking.location.coordinate_latitude,
    longitude: data.booking.location.coordinate_longitude,
    reservation_pretty_id: data.reservation.pretty_id,
    reservation_id: data.request.uuid,
    reservation_state: testState(data).state,
    reservation_start: data.reservation.start_at,
    reservation_end: data.reservation.end_at,
    reservation_created: data.reservation.created_at,
    reservation_actions: testState(data).actions, //["leave_feedback"],
    private_reservation_notes: data.motorcycle.rental_notes,
    rider_uuid: data.rider.uuid,
    rider_pretty_id: data.rider.pretty_id, 
    is_same_day_request: data.reservation.same_day_request,
    start_time_list: data.request.pickup_timings
      ? data.request.pickup_timings
      : null,
    end_time_list: data.request.dropoff_timings
      ? data.request.dropoff_timings
      : null,
  }
}

const ownerInfo = (data) => {
  return {
    owner_id: data.owner.pretty_id,
    owner_name: data.owner.name,
    owner_email: data.owner.email,
    owner_avatar: ownerAvatarURL(data.owner.avatar.cloudinary_public_id),
    owner_avatar_cloudinary_public_id: userPhotoID(
      data.owner.avatar.cloudinary_public_id
    ),
    owner_avatar_large: ownerAvatarURLLarge(
      data.owner.avatar.cloudinary_public_id
    ),
    owner_url: data.owner.is_partner
      ? `/partners/${data.owner.pretty_id}`
      : `/users/${data.owner.pretty_id}`,
    owner_active: data.owner.state === 'active' ? true : false,
    is_partner: data.owner.is_partner,
    partner_hours: data.owner.partner_info.partner_working_hours,
    owner_phone: data.owner.phone ? String(data.owner.phone) : null,
    owner_uuid: data.owner.uuid,
  }
}

const buildInsuranceInfo = (data) => {
  //being REAL certain that these nodes exist

  const { available_services, request_details } = data.financial.pricing

  if (available_services && request_details) {
    if (
      available_services.rider_insurance_options.length > 0 &&
      request_details.rider_insurance
    ) {
      return available_services.rider_insurance_options.find(
        (o) => o.uuid === request_details.rider_insurance
      )
    }
  }

  return {}
}

const paymentInfo = (data) => {
  return {
    purchased_insurance_card_jpg:
      data.booking.insurance_cards.insurance_card_jpg_url,
    purchased_insurance_card_pdf:
      data.booking.insurance_cards.insurance_card_pdf_url,
    insurance_info: buildInsuranceInfo(data),
    reservation_charges_total_cents:
      data.financial.pricing.totals.grand_total.grand_total_cents,
    reservation_charges_total_currency:
      data.financial.pricing.totals.grand_total.grand_total_currency,
    payments_total_amount_cents: 10000,
    payments_total_currency: 'USD',
    payments: data.financial.payment_transactions,
    reservation_charges_line: data.financial.pricing.line_items,
    pricing: data.financial.pricing,
  }
}

const requestInfo = (data) => {
  return {
    request_id: data.request.id,
    request_uuid: data.request.uuid,
    chat_session: data.request.chat_session,
    refunds: data.request.refunds,
    ride_days: data.request.ride_days,
    request_modification: data.request?.modification
  }
}

const bikeSuggestionInfo = (data) => {
  const motorcycle = data.financial.pricing.motorcycle
  return {
    bike_info: {
      make: motorcycle.make,
      category: motorcycle.category,
      year: motorcycle.year,
      displacement_cc: data.motorcycle.displacement_cc,
      daily_rental_price_cents: motorcycle.daily_rental_price.daily_rental_price_cents,
      id: motorcycle.id,
      search_latitude: data.booking.location.search_latitude,
      search_longitude: data.booking.location.search_longitude,
      coordinate_latitude: data.booking.location.coordinate_latitude,
      coordinate_longitude: data.booking.location.coordinate_longitude,
      start_date: moment(data.reservation.start_at).utc().format("YYYY-MM-DD"),
      end_date: moment(data.reservation.end_at).utc().format("YYYY-MM-DD")
    }
  }
}

export const myTripsDetailsTransformer = (data) => {
  return {
    ...motoInfo(data),
    ...reservationInfo(data),
    ...ownerInfo(data),
    ...paymentInfo(data),
    ...requestInfo(data),
    ...bikeSuggestionInfo(data),
  }
}
