import { bikeProfileAPIConnector } from '../transformers/bikeProfileAPIConnector.js'
import { mygarageAPITransformer } from '../transformers/mygarageAPITransformer.js'
import { listingEditAPITransformer } from '../transformers/listingEditAPITransformer.js'
import { listingEditAPITransformerNew } from '../../list/motorcycle/calls/listingEditAPITransformerNew.js'
import { searchAPIConnector } from '../../search/calls/searchAPIConnector.js'
import { mapboxSearchAPIConnector } from '../../search/calls/mapboxSearchApiConnector.js'
import { mapboxBikeSearchAPIConnector } from '../../search/calls/mapboxBikeSearchApiConnector.js'
import { featuredBikesTransformer } from '../transformers/featuredBikesTransformer.js'
import { reviewsFromRidersAPITransformer } from '../../bikes/calls/reviewFromRidersTransformer'
import { recentReviewsTransformers } from '../transformers/recentReviewsTransformers.js'
import { suggestedBikesTransformer } from '../transformers/suggestedBikesTransformer.js'

export const LISTINGS_CALLS = {
  'bike-profile': {
    use_mock: false,
    mock_endpoint: 'listings/bikes/profile/7',
    api_type: 'GET',
    requires_auth: false,
    transFormData(data) {
      return bikeProfileAPIConnector(data)
    },
  },
  'partners-bikes': {
    use_mock: false,
    mock_endpoint: 'users/motorcycles',
    api_type: 'GET',
    requires_auth: true,
  },
  'users-garage': {
    use_mock: false,
    mock_endpoint: 'listings/bikes/users/U111AAA?listing_type=all',
    api_type: 'GET',
    requires_auth: true,
    transFormData(data) {
      return mygarageAPITransformer(data)
    },
  },
  'delist-bike-listing': {
    use_mock: false,
    mock_endpoint: 'bikes/delist',
    api_type: 'PUT',
    requires_auth: true,
  },
  'relist-bike-listing': {
    use_mock: false,
    mock_endpoint: 'bikes/relist',
    api_type: 'PUT',
    requires_auth: true,
  },
  'black-out-date': {
    use_mock: false,
    mock_endpoint: 'owners/blackouts',
    api_type: 'POST',
    requires_auth: true,
  },
  'get-black-out-date': {
    use_mock: false,
    mock_endpoint: 'owners/blackouts',
    api_type: 'GET',
    requires_auth: true,
  },
  'delete-black-out-date': {
    use_mock: false,
    mock_endpoint: 'owners/blackouts',
    api_type: 'DELETE',
    requires_auth: true,
  },
  'try-me-submit': {
    use_mock: false,
    mock_endpoint:
      'motorcycles/try_me?source=HDW&vin=12345WAC&year=2019&make=Harley&model=FatBob&category=Softtail',
    api_type: 'GET',
    requires_auth: true,
  },
  'get-hours-of-availablity': {
    use_mock: false,
    mock_endpoint: 'owners/motorcycles/get',
    api_type: 'GET',
    requires_auth: true,
  },
  'get-price-of-delivery': {
    use_mock: false,
    mock_endpoint: 'motorcycles/delivery/get',
    api_type: 'GET',
    requires_auth: true,
  },
  'get-top-bikes-by-brand': {
    use_mock: false,
    mock_endpoint: 'motorcycles/by_make',
    api_type: 'GET',
    requires_auth: true,
  },
  'edit-hours-of-availablity': {
    use_mock: false,
    mock_endpoint: 'listings/bikes/get',
    api_type: 'PUT',
    requires_auth: true,
  },
  'delete-bike-listing': {
    use_mock: false,
    mock_endpoint: 'bikes/delist',
    api_type: 'PUT',
    requires_auth: true,
  },
  search: {
    use_mock: false,
    mock_endpoint: 'listings/bikes/search',
    api_type: 'GET',
    requires_auth: false,
    transFormData(data) {
      return searchAPIConnector(data)
    },
  },
  'motorcycles-inventory-count': {
    use_mock: false,
    mock_endpoint: 'motorcycles/inventory_count',
    api_type: 'GET',
    requires_auth: false,
  },
  'mapbox-search': {
    use_mock: false,
    mock_endpoint: 'listings/bikes/mapbox/search',
    api_type: 'GET',
    requires_auth: false,
    transFormData(data) {
      return mapboxSearchAPIConnector(data)
    },
  },
  'mapbox-bike-search': {
    use_mock: false,
    mock_endpoint: 'listings/bikes/mapbox/U111AAA',
    api_type: 'GET',
    requires_auth: false,
    transFormData(data) {
      return mapboxBikeSearchAPIConnector(data)
    },
  },
  'create-bike-listing': {
    use_mock: false,
    mock_endpoint: 'bikes/create',
    api_type: 'POST',
    requires_auth: true,
  },
  'set-bike-for-sale': {
    use_mock: false,
    mock_endpoint: 'motorcycles',
    api_type: 'PUT',
    requires_auth: true,
  },
  'update-bike-listing': {
    use_mock: false,
    mock_endpoint: 'listings/bikes/update',
    api_type: 'PUT',
    requires_auth: true,
  },

  'update-bike-for-sale-info': {
    use_mock: false,
    mock_endpoint: 'listings/bikes/update',
    api_type: 'PUT',
    requires_auth: true,
  },

  'model-from-make-year': {
    use_mock: false,
    mock_endpoint: 'models/Ducati/2019',
    api_type: 'GET',
    requires_auth: true,
  },
  'edit-bike-listing': {
    use_mock: false,
    mock_endpoint: 'listings/bikes/users/U111AAA/edit/B111AAA',
    api_type: 'GET',
    requires_auth: true,
    transFormData(data) {
      return listingEditAPITransformer(data)
    },
  },
  'edit-bike-listing-new': {
    use_mock: false,
    mock_endpoint: 'listings/bikes/users/U111AAA/edit/B111AAA',
    api_type: 'GET',
    requires_auth: true,
    transFormData(data) {
      return listingEditAPITransformerNew(data)
    },
  },
  'get-bike-info-from-ids': {
    use_mock: false,
    mock_endpoint: 'motorcycles/list',
    api_type: 'GET',
    requires_auth: false,
  },
  'home-featured-bikes': {
    use_mock: false,
    mock_endpoint: 'marketing/featuredbikes',
    api_type: 'GET',
    requires_auth: false,
    transFormData(data) {
      return featuredBikesTransformer(data)
    },
  },
  'bike-reviews': {
    use_mock: false,
    mock_endpoint: 'listings/bikes/profile/reviews',
    api_type: 'GET',
    requires_auth: false,
    transFormData(data) {
      return reviewsFromRidersAPITransformer(data.reviews)
    },
  },
  'recent-reviews': {
    use_mock: false,
    mock_endpoint: 'motorcycles/ratings/by_location',
    api_type: 'GET',
    requires_auth: false,
    transFormData(data) {
      return recentReviewsTransformers(data)
    },
  },
  'suggested-bikes': {
    use_mock: false,
    mock_endpoint: 'trips/itinerary/TR111AAA/suggested',
    api_type: 'GET',
    requires_auth: true,
    transFormData(data) {
      return suggestedBikesTransformer(data)
    }
  },
}
