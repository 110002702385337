import {
  motoPhotoID,
  userPhotoID,
  userPhotoURL,
  SEARCH_IMAGE_PARAMS
} from "constants/images/sizesAndQuality"
import { findBikeDistanceScore } from "components/search/calls/findDistanceScore"
import { find_price_N_cents } from "components/search/calls/find_price_N_cents"
import cloudinary from "cloudinary-core"
import { CLOUDINARY_NAME } from "constants/images/cloudinary"
import { multiDayPricingList as findMultiDayPricingList } from "components/utils/multiDayPricingList"

const cl = new cloudinary.Cloudinary({
  cloud_name: CLOUDINARY_NAME,
})

const motorcycleImageURL = (motoID) =>
  cl.url(motoPhotoID(motoID), SEARCH_IMAGE_PARAMS)

export const suggestedBikesTransformer = (data) => {
  let suggestedBikes = {
    results: [],
    results_meta: null
  }

  for (let i = 0; i < data.results.length; i++) {
    let dailyRentalPrice = 0
    let multiday5 = 0
    let multiday10 = 0
    let multiday15 = 0
    let multidayN = 0
    const multiDayPricingList = data.results[i].pricing.multi_day_pricing
    dailyRentalPrice = data.results[i].pricing.daily_rental_price.daily_rental_price_cents

    if (multiDayPricingList && multiDayPricingList.length > 0) {
      multidayN = find_price_N_cents(dailyRentalPrice, multiDayPricingList)
    }

    multiday5 = dailyRentalPrice - dailyRentalPrice * (5 / 100)
    multiday10 = dailyRentalPrice - dailyRentalPrice * (10 / 100)
    multiday15 = dailyRentalPrice - dailyRentalPrice * (15 / 100)

    suggestedBikes.results.push({
      motorcycle_id: data.results[i].id,
      bike_id: data.results[i].pretty_id,
      bike_year: data.results[i].year,
      bike_model: data.results[i].model,
      bike_make: data.results[i].make,
      bike_images: data.results[i].images,
      bike_can_book_instantly: data.results[i].can_book_instantly,
      bike_reviews_average: data.results[i].reviews.ratings_average,
      bike_image: motorcycleImageURL(
        data.results[i].images.cloudinary_public_id
      ),
      bike_image_cloudinary_public_id: motoPhotoID(
        data.results[i].images.cloudinary_public_id
      ),
      bike_distance: findBikeDistanceScore(
        data.results[i].id,
        data.results_score
      ),
      bike_owner_avatar: userPhotoURL(data.results[i].owner.avatar.url),
      bike_owner_avatar_id: userPhotoID(
        data.results[i].owner.avatar.cloudinary_public_id
      ),
      bike_category: data.results[i].category,
      bike_created: data.results[i].created_at,
      bike_url: data.results[i].motorcycle_show_page.split('twistedroad.com')[1],
      total_trips_taken: data.results[i].trips_taken,

      bike_has_multiday_discount: data.results[i].pricing.multi_day_discounts,
      bike_multi_day_pricing: findMultiDayPricingList(multiDayPricingList),

      bike_day_price_cents: dailyRentalPrice,
      bike_day_price_currency: data.results[i].pricing.daily_rental_price.daily_rental_price_currency,

      bike_multiday_discount: {
        day_price_N_cents: multidayN,
        day_price_5_cents: multiday5,
        day_price_10_cents: multiday10,
        day_price_15_cents: multiday15,
      },
      bike_pricibg_info: data.results[i].suggested_pricing,
    })
  }

  suggestedBikes.results_meta = {
    total_results: data.results_meta.total,
    page: data.results_meta.page_index, //Number
    total_pages: data.results_meta.total_pages, //Number
    total_rating_count: data.results_meta.total_rating_count,
    average_rating: data.results_meta.average_rating,
  }

  return suggestedBikes
}