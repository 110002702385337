import { apiErrorHandling } from '../apiErrorHandling'
import { CALL_LIBRARY } from '../callLibrary/callLibrary.js'

export const apiGET = (
  data,
  config,
  url,
  processName,
  componentDesc,
  axios
) => {
  return axios
    .get(url, config)
    .then(function(response) {
      //console.log(response)
      //if here is a transformer attached to this data call
      if (CALL_LIBRARY[processName].hasOwnProperty('transFormData')) {
        //console.log(CALL_LIBRARY[processName].transFormData(response.data))
        return CALL_LIBRARY[processName].transFormData(response.data)
      }
      //if we don't transform the data, we just return it as usual
      return response.data
    })
    .catch(function(error) {
      debugger
      if (url.includes('/motorcycles/')) {
        //window.location = '/' //if we get a 404, and user is trying to get to a bike, redirect to home
      } else {
        if (error.response) {
          //we check for a basic response
          if (error.response.status === 404) {
            window.location = '/404' //if we get a 404, then do the 404
          } else {
            throw apiErrorHandling(
              'GET',
              error,
              data,
              componentDesc,
              processName,
              url
            )
          }
        }
      }
    })
}
