import { reservationsTransformer } from '../transformers/reservationsTransformer.js'
import { myTripsDetailsTransformer } from '../transformers/mytripsDetailsTransformers.js'

export const TRIPS_CALLS = {
  'rider-trips': {
    use_mock: false,
    mock_endpoint: 'trips/U111AAA',
    api_type: 'GET',
    requires_auth: true,
    transFormData(data) {
      return reservationsTransformer(data)
    },
  },
  'rider-trip-details': {
    use_mock: false,
    mock_endpoint: 'trips/U111AAA/itinerary/TR111AAA',
    api_type: 'GET',
    requires_auth: true,
    transFormData(data) {
      return myTripsDetailsTransformer(data)
    },
  },
  'rider-selected-bikes': {
    use_mock: false,
    mock_endpoint: 'trips/U111AAA/suggested',
    api_type: 'POST',
    requires_auth: true,
  },
  'leave-feedback': {
    use_mock: false,
    mock_endpoint: 'reservations/feedback/TR111AAA/owner/U111AAA',
    api_type: 'POST',
    requires_auth: true,
  },
  'get-all-favorites': {
    use_mock: false,
    mock_endpoint: 'reservations/feedback/TR111AAA/owner/U111AAA',
    api_type: 'GET',
    requires_auth: true,
  },
  'get-favorites-for-wishlist': {
    use_mock: false,
    mock_endpoint: 'reservations/feedback/TR111AAA/owner/U111AAA',
    api_type: 'GET',
    requires_auth: true,
  },
  'create-new-list': {
    use_mock: false,
    mock_endpoint: 'reservations/feedback/TR111AAA/owner/U111AAA',
    api_type: 'POST',
    requires_auth: true,
  },
  'delete-this-list': {
    use_mock: false,
    mock_endpoint: 'reservations/feedback/TR111AAA/owner/U111AAA',
    api_type: 'DELETE',
    requires_auth: true,
  },
  'add-to-list': {
    use_mock: false,
    mock_endpoint: 'reservations/feedback/TR111AAA/owner/U111AAA',
    api_type: 'PUT',
    requires_auth: true,
  },
  'delete-from-list': {
    use_mock: false,
    mock_endpoint: 'reservations/feedback/TR111AAA/owner/U111AAA',
    api_type: 'DELETE',
    requires_auth: true,
  },
  'request-changes': {
    use_mock: false,
    mock_endpoint: 'reservations/feedback/TR111AAA/owner/U111AAA',
    api_type: 'POST',
    requires_auth: true,
  },
  'cancel-trip': {
    use_mock: false,
    mock_endpoint: 'reservations/feedback/TR111AAA/owner/U111AAA',
    api_type: 'POST',
    requires_auth: true,
  },
}
